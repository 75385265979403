/** @format */

.big-img {
  min-width: 250px;
  max-height: 500px;
}

.box {
  min-width: 290px;
}

.box .row {
  margin-bottom: 15px;
}

.thumb {
  width: 100%;
  height: 100px;
  display: flex;
  cursor: pointer;
  margin: 10px 0;
}
.thumb img {
  width: 90px;
  height: 100%;
  object-fit: cover;
  border: 1px solid #ddd;
  margin-right: 5px;
}

.product-description{
  display: none;
}


@media (max-width: 500px) {
  .thumb {
    height: 50px;
  }
  .thumb img {
    width: 50px;
  }
  .product-description{
    display: block;
  }
}

@media (max-width: 300px) {
  .big-img {
    min-width: 200px
  }
  .box {
    min-width: 200px;
  }

  .view-button {
    display: none;
  }

  .view-cart{
    
    width: 100%;
    display: flex;
    justify-content: flex-end;
    
  }
}
